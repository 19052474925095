<template>
  <div v-if="!['Stage', 'Debrief'].includes($route.name)" class="card-top has-text-centered px-0 pt-4 py-3">
    <div @click="processBack()" class="level-item">
      <i
      class="is-pulled-left fas fa-1x fa-chevron-left pointer is-size-4"
      :class="{
        'has-text-grey-light': !['Stage', 'Debrief'].includes($route.name),
        'has-text-white': ['Stage', 'Debrief'].includes($route.name)
      }"
      ></i>
      <div
      class="ml-2 is-clickable"
      :class="{
        'has-text-grey-light': !['Stage', 'Debrief'].includes($route.name),
        'has-text-white': ['Stage', 'Debrief'].includes($route.name)
      }"
      >
        {{ $route.name === 'Challenge' ? `back to ${p(this.$store.state.adventureTeam, 'task', 2)} page` : 'previous page' }}
      </div>
    </div>
    <!-- <div class="level-item">
      <i class="fas fa-1x fa-minus has-text-grey-light rightie-grip"></i>
      <i class="fas fa-1x fa-minus has-text-grey-light leftie-grip"></i>
    </div> -->
    <!-- <div class="level-item">
      <i class="is-pulled-right fas fa-1x fa-chevron-right pointer has-text-white is-size-4 mt-1 mr-1"></i>
    </div> -->
  </div>
  <div v-else class="mb-5">
  </div>
</template>

<script>
export default {
    name: 'CardNavigation',
    methods: {
      processBack(){
        if(['Stage', 'Debrief'].includes(this.$route.name)){
          return
        } else if(this.$route.name === 'Challenge'){
          this.$router.push({ name: 'Stage' })
        } else {
          this.$router.go(-1)
        }
      }
    }
}
</script>

<style>
.card-top {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.rightie-grip {
  transform: translate(2px, -22px);
}

.leftie-grip {
  transform: translate(-2px, -22px);
}
</style>
